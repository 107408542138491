<template>
	<m-cooperation></m-cooperation>
</template>

<script>
	import MCooperation from '@/components/contact/m-cooperation'
	export default {
		components: {
			MCooperation
		},
	}
</script>

<style>
</style>